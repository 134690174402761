// Set file variable
$filename: 'scss/custom/_header-action.scss';

/*==============================================================================
  @Action - #{$filename}
==============================================================================*/

.action-menu {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  overflow: auto;
  margin: 0 -1em;
  padding: 0;
  background: $accent;
  background: var(--bg-nav, var(--accent, $accent));
  background-image: linear-gradient(to bottom, transparent, rgba($black, 0.1));

  .button {
    flex: 1 0 auto;
    margin: 0 1px 0 0;
    padding: 0.75em 1em;
    background: none;
    color: $white;
    border-radius: 0;
    @include add-icon-button('left');

    &:last-child {
      margin-right: 0;
    }

    &:hover,
    &:focus {
      color: $white;
      background: $accent-hover;
      background: var(--accent-hover, $accent-hover);
    }
  }
  .button-menu {
    flex: 0 0 auto;
  }
  .button.text {
    padding: 0;
    margin-right: 1em;
    border: none;
    font-size: 1.2em;
  }

  a[x-apple-data-detectors] {
    display: none;
  }
}

@include media-min('medium') {
  .action-menu {
    overflow: visible;
    margin: 0;
    padding: 0;
    background: none;

    .button {
      margin-right: 10px;
      padding: 0.75em 1.5em;
      border: 1px solid rgba($color, 0.1);
      border-radius: $border-radius;
      color: $color;

      &:hover,
      &:focus {
        background: none;
        border-color: rgba($color-button, 1);
        border-color: rgba(var(--color-button, $color-button), 1);
        color: $color;
      }
    }
  }
}
