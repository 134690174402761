// Set file variable
$filename: 'scss/custom/_custom.scss';

/*==============================================================================
  @Structure - #{$filename}
==============================================================================*/

body {
  background: $bg-body;
  background: var(--bg-body, var(--shade, $bg-body));
  background-image: $bg-widget-img;
  background-repeat: repeat;
  background-position: center top;
}

.container-page {
  padding: 0;
  margin: 0 auto;
  background: $white;
}

@include media-max('large') {
  .container-page {
    max-width: none;
  }
}

@include media-min('large') {
  .container-page {
    margin: 2em auto;
    box-shadow: 0 2px 8px rgba($black, 0.15);
    border-radius: $page-radius;
  }
}
